/**
 * Modifies the muiTheme with the options from customTheme
 *
 * @param {object} muiTheme
 * @param {object} customTheme
 */

export const modifyMui = (muiTheme, customTheme) => {
  const t = customTheme.ThemeOptions
  muiTheme.customTheme = t

  let fonts = {
    headerFont: '"Butler", serif',
    subHeaderFont: '"Butler", serif',
    bodyFont: '"Oxygen", sans-serif',
  }

  const bodyFont = fonts.bodyFont
  muiTheme.bodyFont = bodyFont
  const headerFont = fonts.headerFont
  muiTheme.headerFont = headerFont
  const buttonFont = fonts.subHeaderFont
  muiTheme.buttonFont = buttonFont

  muiTheme.overrides.MuiCssBaseline = {
    "@global": {
      body: {
        fontFamily: bodyFont,
      },
      "h1, h2, h3, h4, h5, h6": {
        fontFamily: headerFont,
      },
    },
  }
  muiTheme.typography = {
    fontFamily: bodyFont,
    useNextVariants: true,
  }

  // Colours
  muiTheme.palette = {
    primary: {
      green: "#B3D57C",
      main: "#B3D57C",
      purple: "#4B3048",
      navy: "#00313C",
    },
    secondary: {
      gray: "#ACA39A",
      grey: "#ACA39A",
      beige: "#ACA39A",
      main: "#ACA39A",
      cream: "#F7ECe0",
    },
    tertiary: {
      light: "#E6DE77",
      main: "#7CCC6C",
      dark: "#009F4D",
    },
    background: {
      default: "#F7ECE0",
    },
  }

  muiTheme.common = {
    black: "#161615",
    white: "#FCF9F5",
  }

  muiTheme.border = {
    radius: 4,
    thickness: 0,
  }

  muiTheme.shadow = "0 8px 16px rgba(0,49,60,0.16)"

  return muiTheme
}

// function shadeBlend(p,c0,c1) {
//     var n=p<0?p*-1:p,u=Math.round,w=parseInt;
//     if(c0.length>7){
//         var f=c0.split(","),t=(c1?c1:p<0?"rgb(0,0,0)":"rgb(255,255,255)").split(","),R=w(f[0].slice(4)),G=w(f[1]),B=w(f[2]); // eslint-disable-line
//         return "rgb("+(u((w(t[0].slice(4))-R)*n)+R)+","+(u((w(t[1])-G)*n)+G)+","+(u((w(t[2])-B)*n)+B)+")" // eslint-disable-line
//     }else{
//         var f=w(c0.slice(1),16),t=w((c1?c1:p<0?"#000000":"#FFFFFF").slice(1),16),R1=f>>16,G1=f>>8&0x00FF,B1=f&0x0000FF; // eslint-disable-line
//         return "#"+(0x1000000+(u(((t>>16)-R1)*n)+R1)*0x10000+(u(((t>>8&0x00FF)-G1)*n)+G1)*0x100+(u(((t&0x0000FF)-B1)*n)+B1)).toString(16).slice(1) // eslint-disable-line
//     }
// }
