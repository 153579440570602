/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

exports.onRouteUpdate = ({ location }) => scrollToAnchor(location)

exports.onServiceWorkerUpdateReady = () => window.location.reload();

/**
 *
 * @desc - a function to jump to the correct scroll position
 * @param {Object} location -
 * @param {Number} [mainNavHeight] - the height of any persistent nav -> document.querySelector(`nav`)
 */

function scrollToAnchor(location, mainNavHeight = 200) {
    window.scrollTo({top: 0});
    if (location && location.hash) {
        setTimeout(function(){
            const item = document.getElementById(`${location.hash.replace('#', '')}`)
            if(item){
                window.scrollTo({
                    top: item.getBoundingClientRect().top - mainNavHeight,
                    behavior: "smooth",
                })
            }
        }, 750);
    }
    return true
}

/**
 * Set the default cookie consent for GTM
 */
if (typeof window !== "undefined") {
    window.dataLayer = window.dataLayer || [];
    function gtag() { window.dataLayer.push(arguments); }
    gtag('consent', 'default', { 'analytics_storage': 'denied', 'ad_storage': 'denied' });
    window.dataLayer.push({ 'event': 'default_consent' });
    exports.gtag = gtag;
}

/**
 * Set up ActiveCampaign
 */
if (typeof window !== "undefined") {
    (function (e, t, o, n, p, r, i) {
        e.visitorGlobalObjectAlias = n;
        e[e.visitorGlobalObjectAlias] = e[e.visitorGlobalObjectAlias] || function () { (e[e.visitorGlobalObjectAlias].q = e[e.visitorGlobalObjectAlias].q || []).push(arguments) };
        e[e.visitorGlobalObjectAlias].l = (new Date()).getTime();
        r = t.createElement("script");
        r.src = o;
        r.async = true;
        i = t.getElementsByTagName("script")[0];
        i.parentNode.insertBefore(r, i)
    })(window, document, "https://diffuser-cdn.app-us1.com/diffuser/diffuser.js", "vgo");
        
    window.vgo('setAccount', '225196303')
    window.vgo('setTrackByDefault', false)
    window.vgo('process')
}